import React from 'react'
import  './reitoria.css'
import ImgReitora from '../reitoria/ImagemDaReitora.jpg'
import Reitor from '../reitoria/3.png'
import VR from '../reitoria/2.png'
import PRA from '../reitoria/4.png'
import Pro from '../reitoria/1.png'

import { Row, Container, Col, Image } from 'react-bootstrap'
const Reitoria = () => {
    return (
        <>
            <Container fluid>
                <Container>
                <Row>
                    <Col md="12">                 
                    </Col>                    
                </Row>
                </Container>
                
            </Container>
            <Container className="PerfilReitoria">
                <Row>
                    <Col md="4">
                        <Image src={Reitor} thumbnail/>
                    </Col>
                    <Col md="8">
                            <h3>REITOR</h3>
                            {/* <h5>ÁREA CIENTIFICA, PÓS-GRADUAÇÃO E EXTENSÃO</h5> */}
                            <br/>
                            <p>
                            Tiago Caungo Mutombo, Reitor da Universidade Metodista de Angola, é graduado em Ciências da Educação com especialização em Ensino de História, pelo ISCED de Luanda. É doutor em Ciências Históricas, pela Universidad de Oriente, de Cuba.
                            É membro da Associação Portuguesa dos Historiadores Económicos e Sociais, da equipe editorial da Revista electrónica Discente História, da Universidade Federal Recôncavo da Bahia, do Conselho Científico da Revista Angolana de Extensão Universitária e da Comissão Instaladora da Associação Angolana dos Historiadores. É professor convidado do Mestrado em Ensino de História de Angola do ISCED-Luanda. Na Metodista, além de professor da graduação e da pós-graduação, exerceu a função de Vice-Reitor para Área Científica, Pós-Graduação e Extensão.
                                É autor de várias publicações científicas na área de História e Ciências da Educação, com efectiva participação em congressos, conferências, simpósios e outros eventos científicos.

                            </p>
                    </Col>
                </Row>
            </Container><hr></hr>

            <Container className="PerfilReitoria">
                <Row>
                    <Col md="4">
                        <Image src={VR} thumbnail className="ImgStyle"/>
                    </Col>
                    <Col md="8">
                            <h3>Vice-Reitor para a Área Académica</h3>
                            <br/>
                            <p>
                            Damião Miranda Ngonga Alfredo, licenciado em Fisioterapia pela Metodista de Angola, é docente e pesquisador pela Metodista, onde também dirigiu os cursos de Fisioterapia e Cardiopneumologia e exerceu o decanato da Faculdade de Saúde e Bem-Estar.
                            É doutor em Ciências da Saúde, área de concentração Fisioterapia, pelo Programa de Pós-Graduação em Reabilitação e Desempenho Funcional da Universidade de São Paulo.
                            É criador do primeiro aplicativo do ultrassom terapêutico para uso clínico no mundo, que estima a dosagem do ultrassom terapêutico em tecidos biológicos. Tem experiência em pesquisas clínicas em Fisioterapia e Terapia Ocupacional.
                            É coordenador do grupo de pesquisa Reabilitação e Desempenho Funcional da Universidade Metodista de Angola e pesquisador associado do Laboratório de Recursos Fisioterapêuticos/LARF-FMRP/USP-Brasil.
                        </p>
                    </Col>
                </Row>
            </Container><hr></hr>

            <Container className="PerfilReitoria">
                <Row>
                    <Col md="4">
                        <Image src={PRA} thumbnail className="ImgStyle"/>
                    </Col>
                    <Col md="8">
                            <h3>Pró-Reitora de Extensão e Acção Comunitária</h3>
                            <br/>
                            <p>
                            Elvira Moisés da Silva Cazombo, pastora da Igreja Metodista Unida, fez os estudos 
                            teológicos no Seminário Ecuménico Emanuel Unido no Huambo, completando-os na 
                            Universidade Metodista de São Paulo, onde também licenciou-se em Pedagogia, fez 
                            o mestrado e o doutoramento em Ciências da Religião. Tornou-se doutora também em
                             Ciências da Educação pela Universidade Metodista de Piracicaba, São Paulo, Brasil. 
Tem dirigido a investigação científica na intersecção entre Educação, Teologia, Religiões Africanas e Feminismo.
 Em Teologia, pesquisou como o poder das mulheres é apresentado no Livro do Gênesis na representação de Lia, Raquel
  e sua relação com Jacó, para fornecer uma interpretação feminista dos papéis de género nas culturas e 
  religiões africanas. Na Educação, mostra que a valorização da literatura oral (contos e provérbios e outros), 
  em sala de aula, especialmente no ensino primário e secundário, promove a curiosidade e contribui para
   um acesso mais amplo aos bens culturais, à valorização das culturas contextuais. A doutora Elvira Cazombo 
   tem actuado como decana da Faculdade de Teologia da Universidade Metodista de Angola.
   </p>
                    </Col>
                </Row>
            </Container><hr></hr>
            
            <Container className="PerfilReitoria">
                <Row>
                    <Col md="4">
                        <Image src={Pro} thumbnail className="ImgStyle"/>
                    </Col>
                    <Col md="8">
                            <h3>Vice-Reitor para a Área Científica e de Pós-Graduação</h3>
                            <br/>
                            <p>
                            Gerson Queta Jungo é licenciado em Gestão de Computadores e Sistemas de Informação, pela Solusi University no Zimbabwe, mestre e doutor em Ciências da Computação pela University of South África. 

                            Na Universidade Metodista, iniciou como técnico da Direcção de Tecnologia e Informação, exerceu a docência, a coordenação do curso de Mestrado em Engenharia Informática e a função de Decano da Faculdade de Engenharia e Arquitectura.

                            Consultor de Inteligência Artificial pelo banco ABSA e Desenvolvedor de Sistemas Inteligentes pela ProIT, ambas empresas Sul Africanas, é autor da tese Redes Neurais Artificiais - abordagem para prevenir falha de máquinas.
                            
                        </p>
                    </Col>
                </Row>
            </Container>
            
            
            {/* <hr></hr> */}
            {/* <Container className="PerfilReitoria">
                <Row>
                    <Col md="4">
                        <Image src={ImgViceReitor1} thumbnail/>
                    </Col>
                    <Col md="8">
                            <h3>VICE-REITOR</h3>
                            <h5>ÁREA CIENTIFICA, PÓS-GRADUAÇÃO E EXTENSÃO</h5>
                            <br/>
                            <p>
                            Tiago Caungo Mutombo, Vice Reitor para Área Científica, Pós-graduação e Extensão da Universidade Metodista de Angola, é natural do município de Malanje–Bairro do Ritondo, província de Malanje. Graduado em Ciências da Educação com especialização em Ensino de História, pelo Instituto Superior de Ciências da Educação de Luanda, antiga unidade orgânica da Universidade Agostinho Neto. É Doutor em Ciências Históricas, pela Facultat de Ciencias  Sociales da Universidad de Oriente da República de Cuba. Pós Doutorando em Didática do Ensino Superior pela mesma universidade. É também especialista em Metodologia de Investigação Histórica.
É Membro da Associação Portuguesa dos Historiadores Económicos e Sociais (Portugal); Membro da equipe editorial da Revista electrónica Discente História, da Universidade Federal Recôncavo da Bahia, República Federativa do Brasil;  Membro do Conselho Científico da Revista Angolana de Extensão Universitária, afecta a Escola Superior Pedagógica do Bengo, onde foi igualmente Coordenador do Projecto de Investigação Científica RESIOVA; Editor Chefe da Revista Kwijiya, afecto a Faculdade de Teologia da Universidade Metodista de Angola; Membro da Comissão Instaladora da Associação Angolana dos Historiadores; Professor convidado do Mestrado em Ensino de História de Angola do ISCED-LUANDA, entre outras actividades já exercidas no subsistema do ensino geral e universitário do nosso país. 
É Professor da graduação e pós-graduação da Metodista. É autor de várias publicações científicas na área de História e Ciências da Educação, com efectiva participação em congressos, conferências, simpósios e outros eventos científicos.

                            </p>
                    </Col>
                </Row>
            </Container> */}

            
            

        </>   
    )
}

export default Reitoria;