import React, { useState, useEffect } from 'react';
import Carrocel from "../carrocel/carrocel";
import Novidades from "../novidade/novidade";
import BemVindo from "../welcome/BemVindo";
import Faculdades from "../faculdades/faculdades";
import Noticia from "../noticia/noticia";
import Evento from "../evento/evento";
import Estatistica from "../dadosEst/estatistica";
import Video from "../video/video";
import Links from "../linksExternos/links";
import Radio from "../radio/radio";
import Mapa from "../mapa/mapa";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import ImgModal from "./matricula.jpg";
import ComInter from "./ProibiçãoMinisterial.pdf";


function Home() {

  // const [show, setShow] = useState(true);
  

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  
      // useEffect(() => {
      //   const script = document.createElement('script');

      //   script.id = 'botcopy-embedder-d7lcfheammjct';
      //   script.className = 'botcopy-embedder-d7lcfheammjct';
      //   script.type = 'text/javascript';
      //   script.async = true;
      //   script.src = 'https://widget.botcopy.com/js/injection.js';
      //   script.setAttribute('data-botId', '64f64dd0aaf8670009d46bc7');

      //   document.body.appendChild(script);

      //   return () => {
      //     document.body.removeChild(script);
      //   }
      // }, []);

  return (
    <>  
        {/* <df-messenger
          intent="WELCOME"
          chat-title="BotUMA"
          agent-id="e8418788-6920-402d-9c3f-3e91e9de01ee"
          language-code="pt-br"
        ></df-messenger> */}

        <Carrocel />
        <Novidades />
        <BemVindo/>
        <Faculdades/>
        <Noticia/>
        <hr></hr>
        <Evento/>
        <Estatistica/>
        {/* <Radio/> */}
        <hr></hr>
        <Video/>
        <hr></hr>
        <Links/>
        {/* <Mapa/> */}


        {/* <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
         
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <img
                width="100%"
                src={ImgModal}
                alt=""
              />

              
            </Modal.Body>
            <Modal.Footer>
              <a target="_blank" href={ComInter}>
                <Button variant="secondary" onClick={handleClose} >Ver Comunicado</Button>
              </a> 
              <Button variant="primary" href='https://mutue.ao/' target='_blank' >Matricula-se</Button>
            </Modal.Footer>
          </Modal> */}



    </>
  );
}

export default Home;