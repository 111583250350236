import React, { useState, useEffect, useRef } from 'react'
import './noticia.css'
import { Row, Container, Col } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import 'moment/locale/pt';
import f1 from './FOTO1.jpg';
import f2 from './FOTO2.jpg'
import f3 from './FOTO3.jpg'
import f4 from './FOTO4.jpg';
import f5 from './FOTO5.jpg'
import f6 from './FOTO6.jpg'
import f7 from './FOTO7.jpg'
import f8 from './FOTO8.jpg'
import f9 from './FOTO9.jpg'
import f10 from './FOTO10.jpg'

const News = () => {

    const [news, setNews] = useState([]);

    const fecthNews = () => {
        axios.get("https://196.249.246.165:8080/noticia/all").then(res => {
            console.log(res);
            setNews(res.data);

        })
    }

    useEffect(() => {
        fecthNews();
    }, []);

    return news.map((news) => {


        return (
            <>
                <Col md="4" className="NotGeral">
                    {/* <img src={news.img} alt={news.titulo} width="400px" height="300px" /> */}
                    <img src={news.img} alt="" width="400px" />
                    <Link to={`/noticia/${news.codigo}`}> <h5>{news.titulo}</h5></Link>
                    {/* <p>{Moment(news.data).format('DD MMMM YYYY')}</p> */}
                </Col>

            </>
        );
    })
}


const NoticiaPage = () => {
    const [isActive, setIsActive] = useState(null);
    const tratarView = (e) => {
        if (isActive) {
            let elem = document.getElementById(isActive);
            mostrarCorpo(elem.children[1])
            ocultarSpan(elem.children[elem.children.length-1])
            setIsActive(null);
        }
        
        if ( e.currentTarget.id!= isActive){
            let filhos = e.currentTarget.children;
            mostrarCorpo(filhos[1]);
            ocultarSpan(filhos[filhos.length-1]);
            //ocultarSpan(divContainer.children[divContainer.lenght-1]);
            setIsActive(e.currentTarget.id);
        }

    }
    function ocultarSpan(e){
        if (e.className == "noticia_span")
            e.className = "noticia_span_ocultar";
        else
            e.className = "noticia_span";
        
    }

    function mostrarCorpo(e) {
        if (e.className == "noticia_corpo")
            e.className = "noticia_corpo_mostrar";
        else
            e.className = "noticia_corpo";
    }
    return (
        <>
            <Container className='NotPag'>
                <h1>NOTÍCIAS</h1>
                <Row className="noticia_contentor" id="n1" onClick={tratarView}>
                    <h3 className="noticia_titulo">
                        PÓS-GRADUÇÃO REALIZA WORKSHOP SOBRE OS DESAFIOS DA INTERNACIONALIZAÇÃO DO PROFISSIONAL ANGOLANO
                    </h3>
                    <div className="noticia_corpo">
                        <div className="noticia_texto">
                            <p> A Pós-Graduação da Metodista realizou, no dia 21 de Maio, no auditório, um Workshop com o tema os Desafios da Internacionalização do Profissional Angolano, evento que teve como orador o Prof. Doutor Domingos Vita.</p>
                            <p>Na ocasião, entre outros, o encontro teve como principal finalidade explorar os desafios que os angolanos enfrentam aquando de sua participação em actividades de cariz internacional, bem como olhar para as suas vantagens e oportunidades. Durante o workshop, o orador revelou que participar em actividades internacionais garante crescimento pessoal e autonomia, além do contacto com novas culturas académicas, novas tecnologias e as variedades de idiomas.
                            </p>
                            <p>Segundo Morais Malheiro, mestrando em Auditoria e Contabilidade “Gostei da actvidade e devo dizer que daqui para frente os meus desafios serão maiores. Temos que trabalhar para nos internacionalizar e levar Angola adiante”
                            </p>
                            <p>Por sua vez, Gerson de Carvalho, mestrando em Economia, Ordenamento do Território e Desenvolvimento Regional, esclarece que “foi um excelente workshop com um orador capacitado e tive a oportunidade de aprender sobre os principais desafios da internacionalização do profissional angolano. Desejo participar em outros eventos”.
                            </p>
                            <p>Além de estudantes, marcaram presença no evento o Vice-Reitor para a Área Científica e de Pós-Graduação, Professor Doutor Gerson Queta Jungo, professores, coordenadores de cursos, distintos decanos das Faculdades e demais convidados.
                            </p>
                            <p>A Pós-Graduação da Metodista de Angola conta com seis cursos de Mestrados, nomeadamente Auditoria e Contabilidade, Economia e Gestão Aplicadas, Economia, Ordenamento do Território e Desenvolvimento Regional, Gestão Ambiental, Engenharia Informática e Doutoramento em Estudos da Religião e Teologia.
                            </p>
                        </div>
                        <img src={f1} alt='Foto de destaque das actividade' className="noticia_capa" />
                    </div>
                    <span className='noticia_span'>Ver mais</span>
                </Row>

                <Row className="noticia_contentor" id="n2" onClick={tratarView}>
                    <h3 className="noticia_titulo">ESTUDANTES DE ARQUITECTURA FAZEM ANÁLISE ARQUITECTÓNICA E URBANÍSTICA DA MEDIATECA 28 DE AGOSTO
                    </h3>
                    <div className="noticia_corpo">

                        <div className="noticia_texto">
                            <p>Os estudantes do 3º e 4º ano do curso de Arquitectura e Urbanismo da Universidade Metodista de Angola, visitaram, no dia 15 de Maio, as instalações da Mediateca 28 de Agosto.
                            </p>
                            <p>A visita de estudo serviu para compreender a organização espacial e a funcionalidade dos diferentes ambientes da Mediateca, analisar as soluções arquitectónicas utilizadas para atender às necessidades dos usuários, observar o uso de materiais, técnicas construtivas e tecnologias sustentáveis aplicadas no edifício e avaliar o impacto do design na experiência do usuário e na eficiência operacional da Mediateca.
                            </p>
                            <p>Acompanhados pelo professor Israel Neto, os estudantes também tiveram a oportunidade de fazer observações de carácter histórico-arquitetónico no Largo das Heroínas, incluindo as edificações da Rádio Nacional e da Escola Francesa. Durante a jornada, puderam analisar aspectos como o enquadramento urbanístico, paisagismo, formas e percursos, estética e design.
                            </p>
                            <p>A visita de estudo foi uma experiência enriquecedora e fundamental para a formação dos estudantes de Arquitectura, pós os conhecimentos adquiridos e as observações feitas durante a visita contribuirão significativamente para desenvolvimento académico e profissional, fornecendo uma base sólida para futuros projectos e os desafios na área da Arquitectura.
                            </p>
                            <p></p>
                            <p></p>

                        </div>
                        <img src={f2} alt='Foto de destaque das actividade' className="noticia_capa" />
                    </div>
                    <span className='noticia_span'>Ver mais</span>
                </Row>
                <Row className="noticia_contentor" id="n3" onClick={tratarView}>
                    <h3 className="noticia_titulo">
                        ESTUDANTES DE CARDIOPNEUMOLOGIA DA METODISTA INICIAM ESTÁGIO NO PRINCIPAL HOSPITAL DE DOENÇAS CARDIOPULMONARES DE ANGOLA
                    </h3>
                    <div className="noticia_corpo">
                        <div className="noticia_texto">
                            <p>Os estudantes do curso de Cardiopneumologia da Universidade Metodista de Angola, começaram ontem, dia 13 de Maio, o estágio clínico, com a duração de dois meses, no renomado Complexo Hospitalar de Doenças Cardiopulmonares Cardeal Dom Alexandre do Nascimento.
                            </p>
                            <p>Durante o período de estágio, os estudantes serão colocados em diversas áreas do hospital, permitindo-lhes adquirir experiência prática em cardiologia e pneumologia, além de outras disciplinas relacionadas. Essa experiência abrangente proporcionará aos alunos uma visão ampla e aprofundada das complexidades e desafios enfrentados no diagnóstico e tratamento de doenças cardíacas e pulmonares. </p>
                            <p>Além disso, os estudantes terão a oportunidade de trabalhar lado a lado com médicos e profissionais de saúde experientes, absorvendo conhecimento prático e desenvolvendo habilidades essenciais para a prática clínica.
                            </p>
                            <p>O Complexo Hospitalar de Doenças Cardiopulmonares Cardeal Dom Alexandre do Nascimento é reconhecido como o principal centro de referência na área de doenças cardiopulmonares em Angola. Sua infraestrutura moderna e equipa qualificada garantem atendimento de excelência aos pacientes, além de oferecer um ambiente propício para o aprendizado e desenvolvimento profissional dos estudantes.
                            </p>

                        </div>
                        <img src={f3} alt='Foto de destaque das actividade' className="noticia_capa" />
                    </div>
                    <span className='noticia_span'>Ver mais</span>
                </Row>
                <Row className="noticia_contentor" id="n4" onClick={tratarView}>
                    <h3 className="noticia_titulo">ESTUDANTES DE LÍNGUA PORTUGUESA E COMUNICAÇÃO VISITAM MEMORIAL ANTÓNIO AGOSTINHO NETO</h3>
                    <div className="noticia_corpo">
                        <div className="noticia_texto">
                            <p>ESTUDANTES DE LÍNGUA PORTUGUESA E COMUNICAÇÃO VISITAM MEMORIAL ANTÓNIO AGOSTINHO NETO</p>
                            <p>Entre outros, ao longo da visita, enquadrada na disciplina de Literaturas Africanas de Expressão Portuguesa, os estudantes mantiveram um encontro com o escritor António Fonseca, onde tiveram a oportunidade de discutir a obra de sua autoria ´´A Mãe Que Vendeu a Filha na Bruxaria``.
                            </p>
                            <p>O encontro teve como objectivo apresentar aos estudantes as ideias em torno da literatura oral no texto, bem como mobiliza-los para promoção, divulgação e valorização da literatura oral/escrita, fortalecendo as relações entre comunicação e a cultura.
                            </p>
                            <p>Na ocasião, entre outros, os estudantes tiveram também a oportunidade de visitarem o mausóleu e conheceram mais sobre a vida e obra de Agostinho Neto, o Primeiro Presidente de Angola.
                            </p>
                            <p>O curso de Língua Portuguesa e Comunicação da Metodista de Angola aposta na formação de profissionais capazes de se adaptarem com flexibilidade às condições e exigências do mercado de trabalho, nos sectores público e privado do ensino, da comunicação e da cultura, nos media e na sua crítica, na investigação e na gestão cultural.
                            </p>

                        </div>
                        <img src={f4} alt='Foto de destaque das actividade' className="noticia_capa" />
                    </div>
                    <span className='noticia_span'>Ver mais</span>
                </Row>
                <Row className="noticia_contentor" id="n5" onClick={tratarView}>
                    <h3 className="noticia_titulo">UNIVERSIDADE METODISTA MANIFESTA APOIO À PALESTINA</h3>
                    <div className="noticia_corpo">
                        <div className="noticia_texto">
                            <p>Estudantes, professores e a equipa da Reitoria da Universidade Metodista receberam, na sexta-feira, 10 de Maio, o embaixador da Palestina em Angola, Jubrael George Bishara Shomali.
                            </p>
                            <p>Durante a visita, o embaixador falou sobre a crise política e socioeconómica que os palestinianos vivem em decorrência da guerra. Em sete meses, mais de 35 mil pessoas foram mortas pelo exército de Israel, sendo crianças e mulheres a grande maioria delas.
                            </p>
                            <p>Na ocasião, os estudantes expressaram o seu apoio e solidariedade à causa palestina. Para Nestos Varandas, presidente da Associação Académica, a visita do embaixador Jubrael “foi muito proveitosa para a comunidade académica, porque permitiu não apenas compreender os desafios geopolíticos e socioeconómicos que a Palestina vive, como também possibilitou demonstrar a solidariedade dos estudantes da Metodista para com os palestinianos.” Para Nariza de Carvalho, estudante do 1º ano do curso de Gestão e Administração de Empresas, "foi um momento esclarecedor sobre a agressão que os palestinianos sofrem.”
                            </p>
                            <p>Entre outros, o encontro serviu igualmente para fortalecer os laços e estudar parcerias futuras entre o Estado da Palestina e Universidade Metodista. Angola e a Palestina são parceiros estratégicos nos mais variados domínios, com uma relação de amizade e cooperação estabelecida desde os anos 80, poucos anos depois da Independência.
                            </p>
                            <p>Diversas Universidades ao redor do mundo manifestam apoio à Palestina, um acto iniciado pela Universidade de Columbia, nos Estados Unidos da América, que se espalhou por todo mundo.
                            </p>

                        </div>
                        <img src={f5} alt='Foto de destaque das actividade' className="noticia_capa" />
                    </div>
                    <span className='noticia_span'>Ver mais</span>
                </Row>
                <Row className="noticia_contentor" id="n6" onClick={tratarView}>
                    <h3 className="noticia_titulo">ESTUDANTES DA METODISTA PARTICIPAM DO II FÓRUM DE ENERGIA E AMBIENTE
                    </h3>
                    <div className="noticia_corpo">
                        <div className="noticia_texto">
                            <p>Nesta sexta-feira, 10 de Maio, os estudantes do curso de Engenharia Industrial e Sistemas Eléctricos da Universidade Metodista de Angola marcaram presença no II Fórum de Energia e Ambiente. O evento, promovido pelo jornal Expansão, teve lugar no hotel Intercontinental e foi dedicado ao tema "A Futura Matriz Energética em Angola".</p>
                            <p>A participação dos estudantes foi significativa, evidenciando o interesse da nova geração de profissionais angolanos nas questões relacionadas à energia e ao meio ambiente. Acompanhados pela directora do curso, a professora Felecidade Garcia, os alunos aproveitaram a oportunidade para expandir seus conhecimentos, interagir com especialistas do sector e debater sobre os desafios e oportunidades que permeiam a futura matriz energética do país.
                            </p>
                            <p>O fórum proporcionou um espaço enriquecedor para troca de experiências e ideias, destacando a importância da colaboração entre academia, indústria e governo na busca por soluções sustentáveis e inovadoras para os desafios energéticos e ambientais em Angola.
                            </p>
                            <p>A presença activa dos estudantes da Universidade Metodista de Angola no evento reforça o compromisso da instituição com a formação de profissionais engajados e preparados para enfrentar os desafios do desenvolvimento sustentável no país.
                            </p>
                        </div>
                        <img src={f6} alt='Foto de destaque das actividade' className="noticia_capa" />
                    </div>
                    <span className='noticia_span'>Ver mais</span>
                </Row>
                <Row className="noticia_contentor" id="n7" onClick={tratarView}>
                    <h3 className="noticia_titulo">ESTUDANTES PARTICIPAM DE PALESTRA SOBRE LEI GERAL DE PUBLICIDADE</h3>
                    <div className="noticia_corpo">
                        <div className="noticia_texto">
                            <p>Com o objectivo de dialogar com os estudantes sobre matérias relacionadas com a publicidade, assim como reafirmar o compromisso destes com o mercado de trabalho actual, os estudantes da Universidade Metodista de Angola participaram, hoje, 3 de Maio, no auditório, de uma palestra sobre Lei Geral de Publicidade, sob o lema´´conheça a Lei Geral de Publicidade``, uma iniciativa da Direcção Nacional de Publicidade e do Ministério das Telecomunicações, Tecnologias de Informação e Comunicação Social (MINTTICS).
                            </p>
                            <p>O evento, que teve como orador o Director Nacional de Publicidade, José Matuta Cuato, foi marcante para os estudantes da Metodista que, entre outros, tiveram a oportunidade de reter conhecimentos sobre a responsabilidade social da publicidade, os vários tipos de publicidade, assim como suas principais implicações legais.
                            </p>
                            <p>De acordo com Ester Mateus, estudante do 5º ano de Direito da Metodista´´a actividade de hoje foi muito partinente para mim, pois tive noção de que tipo de publicidade devo passar à sociedade, uma vez que nem todas devem ser divulgadas como tem acontecido``.</p>
                            <p>Por sua vez, Maria Fernanda, estudante do 2º ano do curso de Gestão e Administração de Empresas da Metodista, considera  que´´Gostei bastante da palestra porque aprendi sobre a lei da publicidade e suas cláusulas. Foi um encontro proveitoso``.
                            </p>
                            <p>Além de estudantes, o acto contou com a presença do reitor da Metodista, Prof. Doutor Tiago Caungo Mutombo, das directoras dos cursos de Língua Portuguesa e Comunicação e de Gestão e Adminstração de Empresas, do director Nacional de Publicidade, José Matuta Cuato, distintos membros do MINTTICS e demais convidados.
                            </p>
                        </div>
                        <img src={f7} alt='Foto de destaque das actividade' className="noticia_capa" />
                    </div>
                    <span className='noticia_span'>Ver mais</span>
                </Row>
                <Row className="noticia_contentor" id="n8" onClick={tratarView}>
                    <h3 className="noticia_titulo">ESTUDANTES DE ENGENHARIA AGROPECUÁRIA REALIZAM VISITA PRÁTICA AO INSTITUTO GEOGRÁFICO E CADASTRAL DE ANGOLA
                    </h3>
                    <div className="noticia_corpo">
                        <div className="noticia_texto">
                            <p>Com o objectivo de ampliar os conhecimentos sobre georreferenciamento, mapeamento e cadastro territorial, os estudantes de Engenharia Agropecuária da Universidade Metodista de Angola realizaram, no dia 18 de Abril, uma visita prática ao Instituto Geográfico e Cadastral de Angola (IGCA).
                            </p>
                            <p>A visita, coordenada pelo corpo docente da Universidade, permitiu que os estudantes aplicassem os conceitos teóricos aprendidos em sala de aula em um ambiente real de trabalho. Durante a jornada, os estudantes tiveram a oportunidade de interagir com profissionais experientes do IGCA, explorando as mais recentes tecnologias e metodologias utilizadas na análise geoespacial e no cadastro de terras.
                            </p>
                            <p>Para os participantes, essa experiência prática não apenas complementou sua formação académica, mas também os inspirou a compreender a importância do trabalho colaborativo entre o sector educacional e as instituições governamentais para o desenvolvimento sustentável do país.
                            </p>
                            <p>"A visita ao Instituto Geográfico e Cadastral de Angola foi uma oportunidade única para aplicarmos nossos conhecimentos em um contexto real. Aprendemos não apenas sobre técnicas avançadas de mapeamento, mas também sobre a relevância do trabalho em equipa e da colaboração com instituições especializadas", comentou Graça Mendonça, uma das estudantes participantes.
                            </p>
                            <p>O curso forma profissionais alinhados às demandas do mercado e às necessidades do país, preparando os estudantes não apenas para enfrentar os desafios, mas também para contribuir de forma significativa para o avanço da sociedade angolana.
                            </p>
                        </div>
                        <img src={f8} alt='Foto de destaque das actividade' className="noticia_capa" />
                    </div>
                    <span className='noticia_span'>Ver mais</span>
                </Row>
                <Row className="noticia_contentor" id="n9" onClick={tratarView}>
                    <h3 className="noticia_titulo">ESTUDANTES DA METODISTA MARCAM PRESENÇA NA FEIRA ACADÉMICA
                    </h3>
                    <div className="noticia_corpo">
                        <div className="noticia_texto">
                            <p>Os estudantes da Universidade Metodista de Angola, através dos cursos de Arquitectura e Urbanismo e Engenharia Industrial e Sistemas Eléctricos, marcaram presença, no dia 24 de Maio, de uma Feira Académica, no Instituto Superior Politécnico de Tecnologia-ISPTEC, com o tema Sistema Económico de Tecnologias.
                            </p>
                            <p>Durante a feira, os estudantes da Metodista tiveram a oportunidade de apresentar diversas maquetes de Arquitectura num contexto tecnológico, assim como protótipos para monitoramento das redes eléctricas em espaços urbanos.
                            </p>
                            <p>Na ocasião, entre outros, estudantes da Metodista consideram que a experiência de terem participado na feira foi marcante e enriquecedora, uma vez que puderam absorver conhecimentos sobre Inovação Tecnólogica, Economia e Arquitectura. A experiência proporcionou também aos estudantes, por outro lado, uma visão abrangente das dinâmicas actuais e emergentes que moldam o panorama tecnológico económico.
                            </p>
                            <p>A Faculdade de Engenharia e Arquitectura da Metodista assegura uma formação de técnicos habilitados a exercerem as suas actividades com rigor científico e actualidade tecnológica, adequados às diversas realidades nacionais e internacionais.
                            </p>
                        </div>
                        <img src={f9} alt='Foto de destaque das actividade' className="noticia_capa" />
                    </div>
                    <span className='noticia_span'>Ver mais</span>
                </Row>
                <Row className="noticia_contentor" id="n10" onClick={tratarView}>
                    <h3 className="noticia_titulo">ESTUDANTES DE ENGENHARIA INDUSTRIAL E SISTEMAS ELÉCTRICOS VISITAM SUBESTAÇÃO DA RNT
                    </h3>
                    <div className="noticia_corpo">
                        <div className="noticia_texto">
                            <p>Os estudantes do segundo ano do curso de Engenharia Industrial e Sistemas Eléctricos da Universidade Metodista de Angola realizaram uma visita à Subestação da Rede Nacional de Transporte de Energia Eléctrica (RNT-FILDA).
                            </p>
                            <p>A visita, proporcionou aos estudantes, uma oportunidade prática de aprender sobre as operações e os sistemas de energia eléctrica em uma instalação real. Durante a excursão, os estudantes puderam observar de perto o funcionamento interno da subestação, compreendendo os processos de transmissão e distribuição de energia, bem como os protocolos de segurança associados.
                            </p>
                            <p>Na ocasião, os estudantes expressaram entusiasmo com a oportunidade de aplicar seus conhecimentos em um ambiente prático e interagir com profissionais da indústria. "Foi uma experiência incrível poder ver de perto como a electricidade é transmitida e distribuída em larga escala. Isso certamente enriqueceu nossa compreensão do campo", compartilhou José Faria, um dos estudantes. </p>
                            <p>O curso de Engenharia Industrial e Sistemas Eléctricos da Metodista de Angola forma engenheiros habilitados a exercer a sua actividade com rigor científico e actualidade tecnológica, adequados à realidade do país.</p>
                        </div>
                        <img src={f10} alt='Foto de destaque das actividade' className="noticia_capa" />
                    </div>
                    <span className='noticia_span'>Ver mais</span>
                </Row>
                {/* <Row className="noticia_contentor">
                    <h3 className="noticia_titulo"></h3>
                    <div className="noticia_corpo">
                        <div className="noticia_texto">
                            <p></p>
                            <p></p>
                            <p></p>
                            <p></p>
                            <p></p>
                            <p></p>
                        </div>
                        <img src={f2} alt='Foto de destaque das actividade' className="noticia_capa" />
                    </div>
                    <span className='noticia_span'>Ver mais</span>
                </Row> */}
            </Container>
        </>
    )
}

export default NoticiaPage;


