import React from 'react'
import './posgraduacao.css'
import { Row, Container, Col, Image, Card, Button, ListGroup, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import AC from './AUDITORIA E CONTABILIDADE.pdf'
import EGA from './ECONOMIA E GESTÃO APLICADAS.pdf'
import EGA1 from './Admitidos_Economia2023_24.pdf'
import EOTDR from './EOTDREGIONAL.pdf'
import EOTDR1 from './Admitidos_Ordenamento2023_24.pdf'
import ER from './Áreas de Concentração e Linhas de Pesquisa Mestrado e Plano Curricular.pdf'
import GA from './GESTÃO AMBIENTAL.pdf'
import GA1 from './Admitidos_Gestão_Ambiental2023_24.pdf'
import INFO from './INFORMATICA COMPLETO.pdf'
import EI1 from './Admitidos_Informática2023_24.pdf'
import DouTeo from './Áreas de Concentração e Linhas de Pesquisa Doutorado e Plano Curricular.pdf'
import Edital from './Edital_Pos_graduação 2024_2025.pdf'
import Admitidos from './LISTA DE APROVADOS PARA OS CURSOS DE MESTRADO.pdf'

const PosGraduacao = () => {
    return (

        <Container className="">
            <Row>
                <Col md="12" className="PoStyle">
                    <h1>PÓS-GRADUAÇÃO</h1>
                </Col>
            </Row>
            <Row>
                <Col md="12" className="PoStyle">
                    <a target="_blank" href={Edital}>
                        <Button variant="primary">
                            VER O EDITAL
                        </Button>
                    </a>
                    {/* <a target="_blank" href={Admitidos}>
                            <Button variant="primary">
                                LISTA DOS ADMITIDOS
                            </Button>
                    </a> */}
                </Col>
            </Row>
            <br />
            <Row>
                <Col md="12" className="Pos">
                    <p>
                        Até 8 de Setembro, no portal Mutue, <a href='https://portal.mutue.net/register' target='_blank' style={{color:"blue"}}>https://portal.mutue.net/register</a>, estão abertas as inscrições para os seis cursos de Mestrados e um de Doutoramento da Universidade Metodista de Angola.
                        Com 40 vagas para cada curso, a selecção se dará mediante análise do currículo e entrevista realizada pelo Departamento em que o curso está afecto, respeitando o limite de vaga por curso. Nos cursos de Estudos da Religião e Teologia o conhecimento comprovado da língua inglesa, para todas as linhas, e na linha de estudos da Bíblia o conhecimento de Grego ou Hebraico, constam nos critérios de selecção. Os candidatos selecionados serão informados por email, SMS e/ou chamada telefónica.

                    </p>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>LISTA PROVISÓRIA DOS ADMITIDOS</Accordion.Header>
                            <Accordion.Body>
                                <ListGroup>
                                    <ListGroup.Item><a target="_blank" href={EGA1}>Economia e Gestão Aplicadas</a>  </ListGroup.Item>

                                    <ListGroup.Item><a target="_blank" href={EOTDR1}>Economia, Ordenamento do Território e Desenvolvimento Regional</a>  </ListGroup.Item>

                                    <ListGroup.Item><a target="_blank" href={GA1}>Gestão Ambiental</a>  </ListGroup.Item>

                                    <ListGroup.Item><a target="_blank" href={EI1}>Engenharia Informática</a>  </ListGroup.Item>

                                </ListGroup>

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>

            <br />
            <Row>
                <Col md="6" className="PoStyle">
                    <Card bg="light" text="dark" className="">
                        <Card.Header className="Header-Text">
                            <Card.Title>MESTRADOS</Card.Title>
                            <Card.Text>
                            </Card.Text>
                        </Card.Header>
                        <Card.Body>
                            <a target="_blank" href={AC}>AUDITORIA E CONTABILIDADE</a>
                            <hr></hr>
                            <a target="_blank" href={EGA}>ECONOMIA E GESTÃO APLICADAS</a>
                            <hr></hr>
                            <a target="_blank" href={EOTDR}>ECONOMIA, ORDENAMENTO DO TERRITÓRIO E
                                DESENVOLVIMENTO REGIONAL </a>

                            <hr></hr>
                            <a target="_blank" href={ER}>ESTUDOS DA RELIGIÃO E TEOLOGIA</a>

                            <hr></hr>
                            <a target="_blank" href={GA}>GESTÃO AMBIENTAL</a>

                            <hr></hr>
                            <a target="_blank" href={INFO}>ENGENHARIA INFORMÁTICA</a>

                        </Card.Body>

                    </Card>
                    <br />
                </Col>
                <Col md="6" className="PoStyle">
                    <Card bg="light" text="dark" className="">
                        <Card.Header className="Header-Text">
                            <Card.Title>DOUTORAMENTO</Card.Title>
                            <Card.Text className="TextoNoticia">
                            </Card.Text>
                        </Card.Header>
                        <Card.Body>
                            <a target="_blank" href={DouTeo}>ESTUDOS DA RELIGIÃO E TEOLOGIA</a>

                        </Card.Body>

                    </Card>
                </Col>
            </Row>
        </Container>

    )
}

export default PosGraduacao;